import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { RouterProps } from "react-router";
import { Redirect } from "react-router-dom";
import { getStorageData, isEmpty } from "../../../../framework/src/Utilities";
export const configJSON = require("./../config");
export interface Props extends RouterProps {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    redirect: boolean;
    pageLoader: boolean;
    savedPosts: any;
    isModalVisible: any;
    delId: any;
    userProfile: any;
    loginId: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class MySavedPostsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSavePostsDetailsApiId: string;
    deletePostAPiId: string;
    // Customizable Area End 
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.state = {
            redirect: false,
            pageLoader: true,
            savedPosts: [],
            isModalVisible: false,
            delId: null,
            userProfile: null,
            loginId: null,
        };


        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    async componentDidMount() {
        super.componentDidMount();
        let token = await localStorage.getItem("token");
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false });
        } else {
            this.setState({ redirect: true });
        }
        await getStorageData("userProfile").then((res) => {
            console.log("res-->", res);
            this.setState({ userProfile: res, loginId: res?.attributes?.account?.data?.attributes?.account_id });
        });
    }

    redirectUser() {
        if (this.state.redirect) {
            return <Redirect to="/" />;
        }
    }

    async receive(from: String, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getSavePostsDetailsApiId) {
                    console.log("getData", responseJson);
                    if (responseJson.data) {
                        this.setState({ pageLoader: false, savedPosts: responseJson.data });
                    }
                } else if (apiRequestCallId === this.getSavePostsDetailsApiId) {
                    this.setState({ pageLoader: true });
                    this.getSavePostsDetails();
                }
                if (apiRequestCallId === this.deletePostAPiId) {
                    console.log("getDataaaaaa", responseJson);
                    this.setState({ delId: null });
                    this.getSavePostsDetails();
                }
            }
        } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let requesterId = message.getData(
                getName(MessageEnum.SessionRequestedBy)
            );
        }
        // Customizable Area End
    }

    getSavePostsDetails = async () => {
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSavePostsDetailsApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMySavedPostsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getRequestsApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    deletePostPopUp = (id: any) => {
        this.setState({ delId: id, isModalVisible: true });
    }

    deletePost = () => {
        this.setState({ isModalVisible: false });
        const token = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deletePostAPiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_posts/posts/${this.state.delId}/save`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'post'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // Customizable Area Start

    // Customizable Area End
}
