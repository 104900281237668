import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../../framework/src/Messages/MessageEnum";
import axios from "axios";
import { FormInstance } from "antd/lib/form";
import { getStorageData, isEmpty } from "../../../../framework/src/Utilities";
import { Redirect } from "react-router-dom";
// Customizable Area Start
import { createRef } from "react";
// import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { message } from "antd";
import React from "react";
// @ts-ignore
import { baseURL } from '../../../../framework/src/config.js';
import { RouterProps } from "react-router";
// Customizable Area End

export const configJSON = require("../config");
export interface Props extends RouterProps {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    initialActiveNav: any;
    radioValues: boolean;

    isMenuVisible: boolean;
    isUnblockVisible: boolean;
    tabIndex: string;
    selectedSettingId: number;
    isCommunityJoined: boolean;
    isAdmin: boolean;
    isCreatePostVisible: boolean;
    isCreateCommunityVisible: boolean;
    isCreateCommunity: boolean;
    imageUrlCreateCommunity: any;
    imgUrlCreatePost: any;
    loading: boolean;
    allCommunity: any;
    accountDetails: any;
    userProfile: any;
    loginUser: any;
    isBusinessUser: boolean;
    token: string | null;
    isloader: boolean;
    redirect: boolean;
    peoplemayKnow: any;
    allInvitations: any;
    businessConnection: any;
    personalConnection: any;
    invitationUserInfo: any;
    loadInvitationData: any;
    isShowInvitationModal: boolean;
    relationValue: any;
    isMounted: boolean;
    loadCommunity: boolean;
    communityPosts: any;
    profile_pic: any;
    cover_pic: any;
    topics: any;
    description: any;
    name: string;
    likedItems: number[];
    followedItems: number[];
    isReportIssueVisible: boolean;
    reportPostID: any;
    value: any;
    postComments: any;
    joinedCommunities: number[],
    followingCommunities: number[],
    joinRequestSent: number[],
    createDP: any,
    createCoverDP: any,
    toggleCommunityProfile: any,
    AllMedia: any,
    createPostImages: any;
    videoArrayList: any;
    createPostDescription: string;
    communityData: any;
    joiningReq: any;
    postReq: any;
    hiddenPosts: any;
    blockedAccounts: any;
    manageRequests: any;
    postReqUpdate: boolean;
    joinReqUpdate: boolean;
    isloading: boolean;
    updateBlockUser: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CommunityController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    contentRef = createRef<any>();
    isMenuVisible: boolean;
    isUnblockVisible: boolean;
    isCreatePostVisible: boolean;
    isCreateCommunityVisible: boolean;
    routes: any[];
    isCommunityJoined: boolean;
    isAdmin: boolean;
    imageUrlCreateCommunity: any;
    imgUrlCreatePost: any;
    loading: boolean;
    addCommentInPost: any;
    getCommentInPostApiCallID: any;
    getPostRequestsAPICallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            initialActiveNav: 1,
            radioValues: true,
            isMenuVisible: false,
            isUnblockVisible: false,
            tabIndex: "1",
            selectedSettingId: 1,
            isCommunityJoined: false,
            isAdmin: false,
            isCreatePostVisible: false,
            isCreateCommunityVisible: false,
            imageUrlCreateCommunity: "",
            imgUrlCreatePost: "",
            loading: false,
            isCreateCommunity: true,
            allCommunity: '',
            isBusinessUser: false,
            loginUser: {},
            userProfile: {},
            accountDetails: {},
            isloader: true,
            token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
            redirect: false,
            peoplemayKnow: [],
            allInvitations: [],
            businessConnection: [],
            personalConnection: [],
            invitationUserInfo: {},
            loadInvitationData: [],
            isShowInvitationModal: false,
            relationValue: '',
            isMounted: false,
            loadCommunity: false,
            communityPosts: [],
            profile_pic: '',
            cover_pic: '',
            topics: '',
            description: '',
            name: '',
            likedItems: [],
            followedItems: [],
            isReportIssueVisible: false,
            reportPostID: "",
            value: 'Fraude or pyramid Marketing',
            postComments: [],
            joinedCommunities: [],
            followingCommunities: [],
            joinRequestSent: [],
            createDP: {},
            createCoverDP: {},
            toggleCommunityProfile: {},
            AllMedia: [],
            videoArrayList: [],
            createPostImages: [],
            createPostDescription: '',
            communityData: {},
            joiningReq: [],
            postReq: [],
            hiddenPosts: [],
            blockedAccounts: [],
            manageRequests: {},
            postReqUpdate: false,
            joinReqUpdate: false,
            isloading: false,
            updateBlockUser: ''
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.setState({ isMounted: true });
        let token = await localStorage.getItem("token");
        if (token && token !== null && token !== undefined) {
            this.setState({ redirect: false });
        } else {
            this.setState({ redirect: true });
        }

        if (this.state.isMounted) {
            let data: any = await getStorageData("loginUser").then(res => {
                if (res) {
                    this.setState({
                        loginUser: res,
                        isBusinessUser: res?.role === "business"
                    });
                }
                // console.log("loginUser",res)
            });
            await getStorageData("userProfile").then(res => {
                // console.log("userProfile res-->", res);
                this.setState({ userProfile: res });
            });
            await getStorageData("accountData").then(res => {
                // console.log("accountDetails-->", res);
                this.setState({ accountDetails: res });
            });
        }
        // this.getPostRequests();
        // this.getJoinRequests();
        // this.unsubscribe = this.props.navigation.addListener("focus", async () => {
        //   this.getPostRequests();
        //   this.getJoinRequests();
        // });
    }

    redirectUser() {
        if (this.state?.redirect) {
            return <Redirect to="/" />;
        }
    }

    // async componentWillUnmount() { }
    async componentWillUnmount() {
        this.setState({ isMounted: false });

    }
    // *************** api calls *******************************


    async receive(from: String, message: Message) {
        // Customizable Area Start
        // runEngine.debugLog("on recieive==>" + JSON.stringify(message));

        if (getName(MessageEnum.CountryCodeMessage) === message.id) {
            var selectedCode = message.getData(
                getName(MessageEnum.CountyCodeDataMessage)
            );
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.addCommentInPost) {
                    this.setState({
                        isloader: false,
                    });
                    // this.getHomeFeedPosts();
                    window.location.reload();
                } else if (apiRequestCallId === this.getCommentInPostApiCallID) {
                    // console.log("get comments: ", responseJson);
                    // let list = this.state.postList.map((value) =>
                    //   value.id == responseJson.data[0]?.attributes?.post_id
                    //     ? { ...value, comments: responseJson.data }
                    //     : value
                    // );

                    this.setState({
                        isloader: false,
                        postComments: responseJson.data,
                        // postList:list
                    });
                } else {
                    console.log(responseJson, 'responseJson in receive')
                }
            }
        } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let requesterId = message.getData(
                getName(MessageEnum.SessionRequestedBy)
            );
        }

        // Customizable Area End
    }

    // Customizable Area Start

    //get all comments
    getComments = async (id: number) => {
        this.setState({ isloader: true });
        const token = this.state.token;
        const header = {
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCommentInPostApiCallID = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createComment}?post_id=${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getPostsApiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };


    // save posts
    savePost = async (id: number, endString: string) => {
        this.setState({ isloader: true });
        const token = this.state.token;
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getPostsApiEndpoint}/${id}/${endString}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    // add comments
    addComment = (id: number, comment: any) => {
        // console.log(id, comment);
        this.setState({ isloader: true });
        const token = this.state.token;
        const header = {
            "Content-Type": configJSON.getPostsApiContentType,
            token: token,
        };
        let body = {
            data: {
                attributes: {
                    post_id: id,
                    comment: comment,
                    // "taggings_attributes": [
                    //     {
                    //         "account_id": 2
                    //     }
                    // ]
                },
            },
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.addCommentInPost = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createComment}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };


    onItemPress = (id: string) => {

        delete this.props.history?.location?.state;
        this.props.history.replace({
            pathname: '/community/admin-settings', state: {
                id: this.state.communityData.id,
                name: this.state.communityData.name,
                initialActiveNav: id
            }
        });

        this.setState({ initialActiveNav: id });

        // this.props.history.push({
        //     pathname: '/community/admin-settings',
        //     state: {
        //         id: this.state.communityData.id,
        //         name: this.state.communityData.name,
        //         initialActiveNav: id
        //     }
        // })
    }

    //   getDomainSkills(userDataHere: any) {
    //     if (
    //       isEmpty(userDataHere)
    //       // isEmpty(userDataHere.attributes) ||
    //       // isEmpty(userDataHere.attributes.domain_skills)
    //     )
    //       return "";
    //     const domainSkillsPresent = userDataHere;
    //     const domainSkillsStr = domainSkillsPresent.join(", ");
    //     // console.log("domainSkillsStr = ", domainSkillsStr);
    //     return domainSkillsStr;
    //   }

    handleRadioButton = (value: string) => {
        if (value === "Yes, Delete Community") {
            this.setState({ radioValues: true });
        } else if (value === "No, Don't Delete") {
            this.setState({ radioValues: false });
        }
    }

    deleteCommunity = async (id: any) => {
        console.log(id);
        console.log(this.state.radioValues);
        if (this.state.radioValues) {
            try {
                const response = await axios({
                    url: `${baseURL}/bx_block_communityforum/community_forums/${id}`,
                    method: 'DELETE',
                    headers: {
                        'token': this.state.token,
                        'Content-Type': 'application/json'
                    },
                })
                this.props.history.replace({ pathname: '/community/block' })
                // console.log(response.data, 'deleteCommunity');
            } catch (error) {
                console.error(error);
            }
        }
    }

    unBlockCommunity = async (communityId: any, userId: any) => {
        let formdata = new FormData();
        formdata.append("community_forum_id", communityId);
        formdata.append("account_id", userId);
        try {
            const response = await axios({
                url: `${baseURL}/bx_block_follow/community_blocks`,
                method: 'POST',
                headers: {
                    'token': this.state.token,
                    'Content-Type': 'application/json'
                },
                data: formdata
            })
            window.location.reload();
            // this.setState({ postReq: response?.data?.pending_posts?.data })
            console.log(response.data, 'unBlockCommunity');
        } catch (error) {
            console.error(error);
        }
    }

    getManageRequest = async (id: any) => {
        this.setState({ isloading: true })
        try {
            const response = await axios({
                url: `${baseURL}/bx_block_communityforum/community_forums/${id}/settings?manage_requests=true`,
                method: 'GET',
                headers: {
                    'token': this.state.token,
                    'Content-Type': 'application/json'
                },
            })
            // window.location.reload();
            this.setState({ postReqUpdate: response?.data?.post_request, joinReqUpdate: response?.data?.join_request, manageRequests: response?.data, isloading: false })
            console.log(response.data, 'getManageRequest');
        } catch (error) {
            console.error(error);
        }
    }

    getBlockedAccounts = async (id: any) => {
        try {
            const response = await axios({
                url: `${baseURL}/bx_block_communityforum/community_forums/${id}/settings?blocked_accounts=true`,
                method: 'GET',
                headers: {
                    'token': this.state.token,
                    'Content-Type': 'application/json'
                },
            })
            // window.location.reload();
            this.setState({ blockedAccounts: response?.data?.blocked_accounts?.data })
            console.log(response.data, 'getBlockedAccounts');
        } catch (error) {
            console.error(error);
        }
    }
    getHiddenPosts = async (id: any) => {
        try {
            const response = await axios({
                url: `${baseURL}/bx_block_communityforum/community_forums/${id}/settings?hidden_posts=true`,
                method: 'GET',
                headers: {
                    'token': this.state.token,
                    'Content-Type': 'application/json'
                },
            })
            // window.location.reload();
            this.setState({ hiddenPosts: response?.data?.hidden_posts?.data })
            console.log(response.data, 'getHiddenPosts');
        } catch (error) {
            console.error(error);
        }
    }

    add_to_community = async (id: any) => {
        try {
            const response = await axios({
                url: `${baseURL}/bx_block_posts/posts/${id}/add_to_community`,
                method: 'POST',
                headers: {
                    'token': this.state.token,
                    'Content-Type': 'application/json'
                },
            })
            window.location.reload();
            // this.setState({ hiddenPosts: response?.data?.hidden_posts?.data })
            console.log(response.data, 'add_to_community');
        } catch (error) {
            console.error(error);
        }
    }

    editManageRequestFlags = async (id: any) => {
        try {
            const response = await axios({
                url: `${baseURL}/bx_block_communityforum/community_forums/${id}?join_request=${this.state.joinReqUpdate}&post_request=${this.state.postReqUpdate}`,
                method: 'PUT',
                headers: {
                    'token': this.state.token,
                    'Content-Type': 'application/json'
                },
            })
            // window.location.reload();
            // this.setState({ hiddenPosts: response?.data?.hidden_posts?.data })
            console.log(response.data, 'add_to_community');
        } catch (error) {
            console.error(error);
        }
    }

    // Customizable Area End
}
