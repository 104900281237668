import React from "react";
import {
  Grid,
  withStyles,
  Box,
  CircularProgress,
} from "@material-ui/core";
import MySavedPostsController, { Props } from "./MySavedPostsController.web";
import SavedPostsItems from "./SavedPostsItems.web";
import { Row, Typography, Col, Avatar, Empty, Modal, Button } from "antd";
const { Text } = Typography;
// import CustomHeader from "../CustomHeader.web";

class MySavedPosts extends MySavedPostsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getSavePostsDetails();
    // Customizable Area End
  }
  renderCloseModal = () => {
    return (
      <Modal
        // title="Personal Profile"
        visible={this.state.isModalVisible}
        // onOk={() => {
        //   this.deleteData(this.state.modalIndex);
        // }}
        footer={null}
        onCancel={() =>
          this.setState({
            isModalVisible: false
          })
        }
      >
        <p style={{ textAlign: 'center', fontSize: 18, fontWeight: 'bold' }}>
          Want to Delete this post ?
        </p>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            paddingTop: "0.5rem",
            paddingBottom: "1rem"
          }}
        >
          <Col lg={12} sm={12} md={12} xs={12}>
            <Button
              className="btn_colors2"
              style={{ width: 200 }}
              type="primary"
              shape="round"
              size={"large"}
              onClick={() => {
                this.setState({
                  isModalVisible: false
                });
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col lg={12} sm={12} md={12} xs={12}>
            <Button
              className="btn_danger"
              type="primary"
              shape="round"
              size={"large"}
              danger
              style={{ width: "200px" }}
              onClick={() => this.deletePost()}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  };
  render() {
    const { classes } = this.props;
    const { pageLoader, savedPosts } = this.state;
    const pageLoading = (
      <Grid
        container
        style={{ display: "flex", width: "100%", minHeight: "200px" }}
      >
        <Box style={{ margin: "auto" }}>
          <CircularProgress />
        </Box>
      </Grid>
    );

    return (
      <React.Fragment>
        <div style={{ backgroundColor: "#f6f6f6", width: "100%" }}>
          {this.redirectUser()}
          <Row
            style={{
              paddingTop: "15px", marginBottom: 7, marginLeft: 20
            }}
          >
            <Text
              className="textlabel_subheadingText"
            >
              My Profile /
            </Text>
            <Text
              className="textlabel_subheadingText2"
            >
              {" "}
              My Saved Posts
            </Text>
          </Row>
          <span className="textlabel_headingText" style={{ marginLeft: 20 }}>My Saved Posts</span>
          <Grid container style={{ padding: "20px" }}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: "20px",
                backgroundColor: "white",
                borderRadius: "15px",
              }}
            >
              {pageLoader ? (
                pageLoading
              ) : savedPosts.length > 0 ? (
                savedPosts.map((val: any, index: number) => (
                  <Grid item xs={12} key={index + "1"}>
                    <SavedPostsItems
                      classes={classes}
                      item={val}
                      deletePost={this.deletePostPopUp}
                      loginId={this.state.userProfile && this.state.loginId}
                    />
                  </Grid>
                ))
              )
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              }
            </Grid>
          </Grid>
        </div>
        {this.renderCloseModal()}
      </React.Fragment>
    );
  }
}

const styles = () => ({
  deletPost: {
    bottom: 0,
    right: 0,
    background: "white",
    ['@media (max-width:576px)']: { // eslint-disable-line no-useless-computed-key
      minWidth: '26px',
      bottom: 9,
    }
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 4,
    background: "grey",
  },
  postTitle: {
    marginBottom: 0,
    fontSize: 16,
    fontWeight: 600,
  },
  postContainer: {
    padding: "20px",
    borderBottom: "1px solid #e0e0e0",
  },
  Responsive: {
    ['@media (max-width:576px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%'
    }
  }
});

export default withStyles(styles)(MySavedPosts);
