import React from "react";
import PostRatingController, { Props } from "./PostRatingController.web";
import { free_ac, bluepoint, bgblue, orengepoint, pre_ac } from "../assets";
import CommonListItem from "../commonListItem.web";
import "./../userFeed.css";
import moment from "moment";
import {
  Row,
  Col,
  Typography,
  Avatar,
  Button,
  Radio,
  Divider,
  Card,
  List,
  Input,
  Upload,
  Image,
  Badge,
  Tabs,
  Space,
  Modal,
  Form,
} from "antd";
// import CustomHeader from "../CustomHeader.web";
import { InfoCircleFilled, CloseOutlined, CheckCircleFilled } from '@ant-design/icons';
import Check from "./check";
import { Elements } from "@stripe/react-stripe-js";
const { Title, Text } = Typography;

export default class Checkout extends PostRatingController {
  render() {
    const { navigation } = this.props;
    const clientSecret = this.state.clientSecret;

    const appearance: any = {
      theme: 'stripe',
    };
    const stripeOptions = {
      clientSecret,
      appearance,
    };
    const onChange = (e: any) => {
      console.log('radio checked', e.target.value);
      this.setState({ radioValue: e.target.value })
    };

    return (
      <div className="background" style={{ backgroundColor: "#f6f6f6" }}>
        {this.redirectUser()}
        {/* <CustomHeader {...this.props} dotVisible={false} /> */}
        <div>
          <Row style={{ paddingTop: "15px", marginBottom: 7, marginLeft: 20 }}>
            <Text
              className="textlabel_subheadingText"
            >
              My Profile /
            </Text>
            <Text
              className="textlabel_subheadingText2"
            >
              {" "}
              Checkout
            </Text>
          </Row>
          {
            this.state.goCheckout
              ?
              <>
                <Col style={{ marginLeft: "20px", marginTop: "10px" }}>
                  <Text
                    className="textlabel_headingText"
                  >
                    Confirm Plan
                  </Text>
                  {/* <br />
                  <Text
                    className="pre_cap"
                  >
                    Göppert was educated at the Höhere Technische in Göttingen, a
                    school for middle-class girls who aspired to higher education.[5]
                    In 1921, she entered the Frauenstudium, a private high school run
                    by suffragettes that aimed to prepare girls for university. She
                    took the abitur, the university entrance examination, at age 17, a
                    year early, with three or four girls from her school and thirty
                    boys. All the girls passed, but only one of the boys did.
                  </Text> */}
                </Col>
                <div
                  className="checkout_card"
                >
                  <div
                    className="w-30"
                  >
                    <div
                      className="Pre_top"
                      style={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
                    >
                      <Avatar
                        size={60}
                        src={pre_ac}
                      />
                      <Text className="textlabel_UpgradetoPremiumText3">Premium Personal Plan</Text>
                    </div>
                  </div>
                  <div
                    className="checkout_in"
                  >
                    <div className="w-45">
                      <div className="pre_row" style={{ marginTop: 0 }}>
                        <Avatar
                          size={14}
                          src={orengepoint}
                          style={{ marginTop: 2 }}
                        />
                        <Text
                          className="pre_point"
                        >
                          Includes all features of basic plan
                        </Text>
                      </div>
                      <div className="pre_row">
                        <Avatar
                          size={14}
                          src={orengepoint}
                          style={{ marginTop: 2 }}
                        />
                        <Text
                          className="pre_point"
                        >
                          Posted picture and videos will appear on the <br /> user page for a period of 365 days.
                        </Text>
                      </div>
                      <div className="pre_row">
                        <Avatar
                          size={14}
                          src={orengepoint}
                          style={{ marginTop: 2 }}
                        />
                        <Text
                          className="pre_point"
                        >
                          Create personalized questionnaire for each <br /> category
                        </Text>
                      </div>
                      <div className="pre_row">
                        <Avatar
                          size={14}
                          src={orengepoint}
                          style={{ marginTop: 2 }}
                        />
                        <Text
                          className="pre_point"
                        >
                          Advanced analytics on the ratings
                        </Text>
                      </div>
                    </div>
                    <div
                      className="confirm_plan_btn"
                    >
                      <Button
                        style={{ position: 'absolute', border: 'none', color: '#668097', top: -52, right: 0, boxShadow: 'none' }}
                        onClick={() =>
                          this.props.history.push({
                            //@ts-ignore
                            pathname: '/Premium/Account'
                          })
                        }
                      >Change Plan</Button>
                      <Text
                        className="attrbuted_textlabel_UpgradetoPremiumText3"
                        style={{ color: '#497db3' }}
                      >
                        ${this.state.premiumPlan?.attributes?.yearly_plan} <span className="attrbuted_textlabel_UpgradetoPremiumText4">/ Year</span>
                      </Text>
                      <Button
                        className="pre_plan_btn login_btn_colors__kpBfq"
                        onClick={() => this.setState({
                          goCheckout: false
                        })}
                      >
                        Continue to Payment
                      </Button>
                    </div>
                  </div>
                </div>
              </>
              :
              <>
                <Col style={{ marginLeft: "20px", marginTop: "10px" }}>
                  <Text
                    className="textlabel_headingText"
                  >
                    Payment
                  </Text>
                  {/* <br />
                  <Text
                    className="pre_cap"
                  >
                    Göppert was educated at the Höhere Technische in Göttingen, a
                    school for middle-class girls who aspired to higher education.[5]
                    In 1921, she entered the Frauenstudium, a private high school run
                    by suffragettes that aimed to prepare girls for university. She
                    took the abitur, the university entrance examination, at age 17, a
                    year early, with three or four girls from her school and thirty
                    boys. All the girls passed, but only one of the boys did.
                  </Text> */}
                </Col>
                <div
                  className="radio_area"
                >
                  <div>
                    <Radio.Group onChange={onChange}>
                      <Space direction="vertical">
                        <Radio
                          value={1}
                          className='radio_btn'
                        >Debit / Credit / ATM Card</Radio>
                        {
                          this.state.radioValue === 1 &&
                          <div>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#a6a6a6",
                                fontWeight: "bold",
                              }}
                            >
                              Add Card Details
                            </p>
                            <Input
                              className="info"
                              placeholder="Card information will not be saved"
                              defaultValue="Card information will not be saved"
                              disabled={true}
                              prefix={<InfoCircleFilled translate={undefined} />}
                              suffix={<CloseOutlined translate={undefined} />} />
                            {/* <Form.Item
                              name="cardname"
                              rules={[
                                {
                                  required: true,
                                  message: 'The name is required.',
                                },
                                {
                                  pattern: /^[a-zA-Z0-9]+$/,
                                  message: 'Name can only include letters and numbers.',
                                },
                              ]}
                            >
                              <Input
                                className="card_det"
                                type={"text"}
                                placeholder={"Cardholder Name"}
                                // onChange={e => onHandleChange(e.target.value)}
                                onChange={e => this.setState({ cardHolderName: e.target.value })}
                              />
                            </Form.Item> */}
                            {this.state.clientSecret && (
                              <Elements options={stripeOptions} stripe={this.state.stripePromise}>
                                <Check token={this.state.token} />
                              </Elements>
                            )}
                          </div>
                        }
                        {/* <Radio
                          value={2}
                          className='radio_btn'
                        >Net Banking</Radio> */}
                      </Space>
                    </Radio.Group>
                  </div>
                  <div>
                    {this.SummaryData()}
                  </div>
                </div>
              </>
          }
        </div>
      </div>
    );
  }
  SummaryData() {
    return <div style={{ width: 350 }}>
      <div className="summary"
        style={{ backgroundColor: '#cedce7', borderRadius: '5px 5px 0px 0px', padding: '20px 20px 10px 20px' }}>
        <span style={{ fontWeight: 'bold', fontSize: 18, color: '#353b41' }}>Summary</span>
        <span style={{ cursor: "pointer" }} data-testid="EditPlan"onClick={() => this.props.history.push("/Premium/Account")}>Edit plan</span>
      </div>
      <div style={{ backgroundColor: '#e0e7ed', borderRadius: '0px 0px 5px 5px', padding: '0px 15px' }}>
        <div className="summary" style={{ paddingTop: 10, paddingBottom: 10 }}>
          <span style={{ fontSize: 18, fontWeight: 'bold', color: '#353b41' }}>Premium Personal Plan</span>
          <span style={{ fontSize: 18, fontWeight: 600, color: '#3e4143' }}>${this.state.premiumPlan?.attributes?.yearly_plan}</span>
        </div>
        <div className="summary">
          <span style={{ fontSize: 18, color: '#777680', fontWeight: 400 }}>Subtotal</span>
          <span style={{ fontSize: 18, fontWeight: 600, color: '#3e4143' }}>${this.state.premiumPlan?.attributes?.yearly_plan}</span>
        </div>
        <div className="summary">
          <span style={{ fontSize: 18, color: '#777680', fontWeight: 400 }}>Shipping</span>
          <span style={{ fontSize: 18, fontWeight: 600, color: '#3e4143' }}>$00.0</span>
        </div>
        <Divider style={{ margin: '15px auto' }} />
        <div className="summary" style={{ paddingBottom: 15 }}>
          <span style={{ fontSize: 17, fontWeight: 'bold', color: '#6b6b78' }}>Total</span>
          <span style={{ fontSize: 18, fontWeight: 'bold', color: '#3e4143' }}>${this.state.premiumPlan?.attributes?.yearly_plan}</span>
        </div>
      </div>
    </div>
  }
}

