import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: 300 + theme.spacing(3) * 2,
    width: "100%"
  },
  margin: {
    height: theme.spacing(3),
  },
}));

function ValueLabelComponent(props: any) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const AirbnbSlider = withStyles({
  root: {
    color: "#b24040",
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    // backgroundColor: "#b24040",
    color: "#ffffff",
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -3,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider);

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

export default function SliderView(props: any) {
  // Customizable Area Start
  const classes = useStyles();
  const [value, setValue] = useState(props.v);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    props?.changeHandler(newValue)
  };


  const AirbnbThumbComponent = (props2: any) => {
    // console.log(props?.description);
    return (
      <span {...props2}>
        <div style={{
          backgroundColor: Math.round(value) < 60 ? '#b24040' : Math.round(value) < 80 ? '#3a87a8' : "#3c9b83",
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          borderColor: Math.round(value) < 60 ? '#b24040' : Math.round(value) < 80 ? '#3a87a8' : "#3c9b83",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <Typography style={{
            fontSize: "9px",
          }}>{Math.round(value)}%</Typography>
        </div>
      </span>
    );
  }

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant='body2'>{props.name}{" "}
        {props?.description && <Tooltip title={props?.description}>
          <ExclamationCircleOutlined
            translate={undefined}
            style={{ marginLeft: "0.4rem", cursor: "pointer", color: "#919191" }}
          />
        </Tooltip>}
      </Typography>
      <AirbnbSlider
        ThumbComponent={AirbnbThumbComponent}
        onChange={handleChange}
        defaultValue={props.v}
        style={{
          color: Math.round(value) < 60 ? '#b24040' : Math.round(value) < 80 ? '#3a87a8' : "#3c9b83"
        }}
      />
    </div>
  );
  // Customizable Area End
}
