import React from "react";
import "../communityManage.css";
import { DeleteImgPng, settingIcon, blockIcon, eyehiddenIcon } from "../assets"
import {
    Row,
    Col,
    Modal,
    Typography,
    List,
    Breadcrumb,
    Button, Avatar,
    Empty,
    Spin, Divider,
    Menu
} from "antd";
import CommunitySettingsController, { Props } from "./CommunitySettingsController.web";
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
// import CustomHeader from "../CustomHeader.web";
import moment from "moment";
import GroupsDeleteCommunity from "../../../Groups/src/DeleteCommunity.web";
const { Text } = Typography;

const data = [
    {
        id: 1,
        icon: settingIcon,
        title: 'Manage requests',
    },
    {
        id: 2,
        icon: blockIcon,
        title: 'Blocked Accounts',
    },
    {
        id: 3,
        icon: eyehiddenIcon,
        title: 'Hidden Posts',
    },
    {
        id: 4,
        icon: DeleteImgPng,
        title: 'Delete Community',
    },
];

const PurpleSwitch = withStyles({
    switchBase: {
        //   color: '#8db8d9',
        '&$checked': {
            color: '#4080c0',
        },
        '&$checked + $track': {
            backgroundColor: '#4080c0',
        },
    },
    checked: {},
    track: {},
})(Switch);

export default class CommunitySettings extends CommunitySettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        const { } = this.state;
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        let communityInfo: any = this.props?.history?.location?.state;
        // console.log(communityInfo, 'communityId***');
        this.setState(({ communityData: communityInfo, initialActiveNav: communityInfo?.initialActiveNav }));

        if (communityInfo) {
            await this.getManageRequest(communityInfo?.id);
            await this.getBlockedAccounts(communityInfo?.id);
            await this.getHiddenPosts(communityInfo?.id);
            // await this.getPostRequests(communityInfo);
        }
    }

    manageRequests = [
        {
            id: 1,
            title: 'Post Requests',
            description: 'You can decide what post will get posted or rejected',
            switchValue: this.state.postReqUpdate
        },
        {
            id: 2,
            title: 'Joining Request',
            description: 'You can decide who can join community',
            switchValue: this.state.joinReqUpdate
        },
    ]

    onChange(checked: any, value: any) {
        console.log(`switch to ${checked}& ${value}`);
        if (value === 'Post Requests') {
            this.setState({ postReqUpdate: checked })
        } else {
            this.setState({ joinReqUpdate: checked })
        }
        // this.editManageRequestFlags("post_request", value)
    }

    handleOk = () => {
        // console.log(e);
        this.setState({ isUnblockVisible: false });
        this.unBlockCommunity(this.state.communityData.id, this.state.updateBlockUser);
    };

    handleCancel = (e: any) => {
        console.log(e);
        this.setState({ isUnblockVisible: false });
    };
    renderUnBlockModal = () => {

        return (
            <Modal
                width={320}
                style={{ borderWidth: "0px", borderRadius: "4px", overflow: "hidden" }}
                centered
                // placement="bottomRight"
                title={
                    <div
                        style={{
                            width: "100%",
                            cursor: "move",
                            fontWeight: "bold",
                            borderWidth: "0px"
                        }}
                        onFocus={() => { }}
                        onBlur={() => { }}
                    // end
                    >
                        Unblock Confirmation
                    </div>
                }
                visible={this.state.isUnblockVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <Row style={{ padding: '0px 10px 10px 10px' }}>
                        <Col span={12}>
                            <Button
                                size="large"
                                className="report-button"
                                key="report"
                                onClick={this.handleCancel}
                            >
                                No,Don't unblock
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button
                                size="large"
                                className="follow-label"
                                style={{ borderWidth: "0px" }}
                                key="report"
                                onClick={this.handleOk}
                            >
                                Yes, unblock
                            </Button>
                        </Col>
                    </Row>
                ]}
            >
                <Text>{`Are you sure want to unblock ${this.state.allCommunity} ?`}</Text>
                {/* <Text>{`Are you sure want to unblock ${item.key}?`}</Text> */}
            </Modal>
        );
    };

    getDifference(created_at: any) {
        let difference = 0;
        let differenceStr = "";
        const dateForDifference = moment(created_at);
        difference = moment().diff(dateForDifference, "days");
        console.log("today = ", moment().diff(dateForDifference, "days"));
        if (difference === 0) {
            let hours = moment().diff(dateForDifference, "hours");
            if (hours === 0) {
                let minutes = moment().diff(dateForDifference, "minutes");
                differenceStr = minutes + " min ago";
            } else differenceStr = hours + " hr ago";
        } else {
            if (difference === 1) differenceStr = "1 day ago";
            else differenceStr = difference + " days ago";
        }

        return <span style={{ color: 'gray' }}>{differenceStr}</span>;
    }



    // Customizable Area End
    render() {
        // const { navigation } = this.props;
        return (
            <div
                className="searchStyles"
                style={{ background: "#f6f6f6", minHeight: "100vh" }}
            >
                {/* <CustomHeader {...this.props} dotVisible={false} /> */}
                {this.redirectUser()}
                <div style={{ padding: '1rem' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item>Community</Breadcrumb.Item>
                        <Breadcrumb.Item className="anchor_element" onClick={() => this.props.history.push({ pathname: '/community/block', state: { communityId: this.state.communityData?.id } })}>
                            <a>{this.state.communityData?.name}</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item className="follow-label">Community Settings</Breadcrumb.Item>
                    </Breadcrumb>
                    <Row>
                        <Col style={{ padding: '16px' }} span={6}
                            xs={24}
                            sm={24}
                            md={10}
                            lg={8}
                            xl={6}
                            xxl={6}>
                            <h2 style={{ color: '#434343' }}>Community Settings </h2>
                            <Menu
                                theme="light"
                                style={{
                                    width: "100%",
                                    color: "rgba(151, 151, 151, 1)",
                                    borderRadius: "5px",
                                    overflow: "hidden"
                                }}
                                mode="inline"
                            >
                                {
                                    data.map((item: any, id: number) => {
                                        return (
                                            <Menu.Item key={item.id} className="customclass" style={{ width: "100%", height: "3rem", borderBottom: id + 1 < data?.length ? "1px solid #ededed" : "none", display: "flex", flexDirection: "row", alignItems: "center" }} onClick={() => this.onItemPress(item.id)}>
                                                <img src={item.icon} style={{ width: "20px", height: '20px', marginRight: "10px" }} />
                                                <span style={{ fontWeight: 500, fontSize: 15 }}>{item.title}</span>
                                            </Menu.Item>
                                        )
                                    })
                                }
                            </Menu>
                            {/* <List
                                itemLayout="horizontal"
                                style={{ background: '#fff', cursor: 'pointer' }}
                                dataSource={data}
                                renderItem={(item: any) => (
                                    <List.Item onClick={() => this.onItemPress(item.id)}
                                        style={{ background: this.state?.initialActiveNav === item.id ? '#e4e4e4' : '#fff' }}>
                                        <List.Item.Meta
                                            className="community_setting_css"
                                            style={{ paddingLeft: '1rem', color: '#6a6a6a' }}
                                            avatar={item.icon}
                                            title={<span style={{ color: '#6a6a6a' }}>{item.title}</span>}
                                        />
                                    </List.Item>
                                )}
                            /> */}
                        </Col>
                        <Col
                            style={{ padding: "16px", }}
                            xs={24}
                            sm={24}
                            md={24}
                            lg={14}
                            xl={14}
                            xxl={14}>
                            {this.state.initialActiveNav === 1 && (
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            <h2 style={{ color: '#434343' }}>Manage Requests</h2>
                                        </div>
                                        <div>
                                            <Button
                                                shape="round"
                                                style={{ border: "1px solid #00698f", color: "#00698f", marginBottom: "5px", width: "5rem", height: "1.8rem", padding: "1px" }}
                                                onClick={() => this.editManageRequestFlags(this.state.communityData.id)}
                                            >
                                                Save
                                            </Button>
                                            {/* <Button size="large" className="settings-button" style={{ width: "172px" }} key="report"
                                                onClick={() => this.editManageRequestFlags(this.state.communityData.id)}>
                                                Save
                                            </Button> */}
                                        </div>
                                    </div>
                                    <div style={{ background: '#fff' }}>
                                        {this.state.isloading ? (
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Spin />
                                            </div>
                                        ) : (
                                            // <List
                                            //     itemLayout="horizontal"
                                            //     style={{ background: '#fff', cursor: 'pointer' }}
                                            //     dataSource={this.manageRequests}
                                            //     renderItem={(item: any) => (
                                            <div >
                                                <List.Item
                                                    actions={[<div style={{ position: 'relative' }}>
                                                        <PurpleSwitch
                                                            defaultChecked={this.state.postReqUpdate}
                                                            onChange={(e: any) => this.setState({ postReqUpdate: e.target.checked })}
                                                        />
                                                        {/* <Switch size="default"
                                                    // className={this.state.postReqUpdate ?"ant-switch-handle":''}
                                                        style={{ fontSize: '1rem',backgroundColor:this.state.postReqUpdate ?'#a7cbe6':'lightgrey' }}
                                                         defaultChecked={this.state.postReqUpdate}
                                                        onChange={(e) => this.setState({ postReqUpdate: e.target.checked })} /> */}
                                                    </div>]
                                                        // }
                                                    }
                                                >
                                                    <List.Item.Meta
                                                        style={{ paddingLeft: '1rem', color: '# ' }}
                                                        // avatar={item.icon}
                                                        title={<span style={{ color: '#6a6a6a' }}>Post Requests</span>}
                                                        description={'You can decide what post will get posted or rejected'}
                                                    />
                                                </List.Item>
                                                <Divider style={{ margin: '5px' }} />
                                                <List.Item
                                                    actions={[<div style={{ position: 'relative' }}>
                                                        <PurpleSwitch
                                                            defaultChecked={this.state.joinReqUpdate}
                                                            onChange={(e: any) => this.setState({ joinReqUpdate: e.target.checked })}
                                                        />
                                                    </div>]
                                                    }
                                                >
                                                    <List.Item.Meta
                                                        style={{ paddingLeft: '1rem', color: '#6a6a6a' }}
                                                        // avatar={item.icon}
                                                        title={<span style={{ color: '#6a6a6a' }}>Joining Request</span>}
                                                        description={'You can decide who can join community'}
                                                    />
                                                </List.Item>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {this.state.initialActiveNav === 2 && (
                                <div>
                                    <div><h2 style={{ color: '#434343' }}>Blocked Acounts</h2></div>
                                    <div style={{ background: '#fff' }}>
                                        {this.state.blockedAccounts?.length > 0 ? (
                                            <List
                                                itemLayout="horizontal"
                                                style={{ background: '#fff', cursor: 'pointer' }}
                                                dataSource={this.state.blockedAccounts}
                                                renderItem={(item: any) => (
                                                    <List.Item
                                                        actions={[<Button className="settings-button" key="report"
                                                            onClick={() => this.setState({ updateBlockUser: item.id, isUnblockVisible: true, allCommunity: item.attributes.full_name })}>
                                                            Unblock
                                                        </Button>]}
                                                    >
                                                        <List.Item.Meta
                                                            style={{ paddingLeft: '1rem', color: '#6a6a6a' }}
                                                            avatar={
                                                                <Avatar
                                                                    src={item?.attributes?.photo}
                                                                    // size={40}
                                                                    style={{ backgroundColor: "lightgrey" }}
                                                                />
                                                            }
                                                            title={<span style={{ color: '#6a6a6a' }}>{item.attributes.full_name}</span>}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "100px",
                                                    textAlign: "center",
                                                    marginTop: 0
                                                }}
                                                // description={JSON.stringify(this.state.toggleCommunityProfile)}
                                                description={"No Blocked Accounts on this community page"}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {this.state.initialActiveNav === 3 && (
                                <div>
                                    <div><h2 style={{ color: '#434343' }}>Hidden Posts </h2></div>
                                    <div style={{ background: '#fff' }}>
                                        {this.state.hiddenPosts?.length > 0 ? (
                                            <List
                                                itemLayout="horizontal"
                                                style={{ background: '#fff', cursor: 'pointer' }}
                                                dataSource={this.state.hiddenPosts}
                                                renderItem={(item: any) => (
                                                    <List.Item
                                                        actions={[<Button size="large" shape="round" className="follow-outline" key="report"
                                                            onClick={() => this.add_to_community(item.id)} style={{ width: "100%" }}>
                                                            <Text style={{ fontSize: 16, color: "#005574" }}>Add to Community</Text>
                                                        </Button>]}
                                                    >
                                                        <List.Item.Meta
                                                            style={{ paddingLeft: '1rem', color: '#6a6a6a' }}
                                                            avatar={
                                                                <Avatar
                                                                    src={item?.attributes?.account?.data?.attributes?.photo}
                                                                    // size={40}
                                                                    style={{ backgroundColor: "lightgrey" }}
                                                                />}
                                                            title={<span style={{ color: '#6a6a6a' }}>{item?.attributes?.account?.data?.attributes?.first_name}
                                                                &nbsp;<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}> posted, "{item?.attributes?.body}"</span></span>}
                                                            description={this.getDifference(item?.attributes?.updated_at)}
                                                        />
                                                    </List.Item>
                                                )}
                                            />
                                        ) : (
                                            <Empty
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                style={{
                                                    backgroundColor: "white",
                                                    padding: "100px",
                                                    textAlign: "center",
                                                    marginTop: 0
                                                }}
                                                // description={JSON.stringify(this.state.toggleCommunityProfile)}
                                                description={"No Hidden Posts on this community page"}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {this.state.initialActiveNav === 4 && (

                                <div>
                                    <GroupsDeleteCommunity
                                        communityId={this.state.communityData?.id}
                                        history={this.props.history}
                                    />
                                </div>
                            )}

                        </Col>
                    </Row>
                </div>
                {this.renderUnBlockModal()}
                {/* {this.renderUnBlockModal(postData[0])} */}
            </div>
        );
    }

    // async componentDidMount() { }
}
// Customizable Area Start

// Customizable Area End
