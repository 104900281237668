Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.POSTMethod = "POST";
exports.PUTMethod = "PUT";
exports.DELETEMethod = "DELETE";
exports.APPLICATIONJSONContentType = "application/json";

exports.exampleApiContentType = "application/json";

exports.GetProfileInfoEndPoint = "bx_block_profile/profiles";
exports.SearchAPIMethod = "GET";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";
exports.SearchuserEndAPI = "bx_block_search/search?search=";
exports.SearchAPIMethod = "GET";
exports.newUserSearchApi = "bx_block_search/search/user_search?search=";
exports.loginApiContentType = "";
exports.GetCategoriesEndPoint = "bx_block_categories/categories";
exports.GetSendRegEndPoint = "bx_block_request_management/requests";
exports.btnExampleTitle = "CLICK ME";
exports.postendpoint = "bx_block_posts/posts";

// Home Feed Posts API details
exports.getPostsApiContentType = "application/json";
exports.getPostsApiEndpoint = "bx_block_posts/posts";
exports.getUserPostsApiEndpoint = "bx_block_posts/posts/fetch_user_posts";
exports.getPostsApiMethod = "GET";

// Home Edit Posts API details
exports.getEditApiContentType = "application/json";
exports.getEditApiEndpoint = "bx_block_posts/posts/";
exports.getEditApiMethod = "PUT";

//Account Details
exports.getAccountDetailsEndPoint = "account_block/accounts";
exports.getAccountDetailsApiMethod = "GET";

exports.postendpoint = "bx_block_posts/posts";
exports.createPostApiContentType = "multipart/form-data";

exports.createComment = "bx_block_comments/comments";
exports.getNetworkEndPoint = "bx_block_request_management/requests";
exports.getKnownPeopleEndPoint =
  "bx_block_request_management/requests/suggestions";
exports.getNetworkApiMethod = "GET";

exports.SearchAPIMethod = "GET";
exports.likeDislikeApiMethod = "POST";

exports.GetNetConnectionEndPoint = "bx_block_request_management/requests";
exports.GetNetConnectionAPIMethod = "GET";

exports.createEditPostApiContentType = "multipart/form-data";

//add comment to the post
exports.addCommentApiEndPoint = "bx_block_comments/comments";
exports.addCommentApiContentType = "application/json";
exports.addCommentMethodType = "POST";

exports.getCommentsApiContentType = "application/json";
exports.getCommentsApiEndpoint = "bx_block_comments/comments";
exports.getCommentsApiMethod = "GET";

//get community list
exports.getCommunitiesApiContentType = "application/json";
exports.getCommunitiesApiEndpoint = "bx_block_groups/community_forums";
exports.getCommunitiesApiMethod = "GET";

//create a community, edit community, edit community settings flags
exports.createEditCommunityApiContentType = "multipart/form-data";
exports.createEditCommunityApiEndPoint =
  "bx_block_groups/community_forums";
exports.createCommunityMethodType = "POST";
exports.editCommunityMethodType = "PUT";

//delete a community
exports.deleteCommunityApiContentType = "application/json";
exports.deleteCommunityApiEndPoint = "bx_block_groups/community_forums";
exports.deleteCommunityMethodType = "DELETE";

//get a community data
exports.getCommunityDataApiContentType = "application/json";
exports.getCommunityDataApiEndpoint =
  "bx_block_groups/community_forums";
exports.getCommunityDataApiMethod = "GET";

//get community settings flags
exports.getSetttingsFlagsApiContentType = "application/json";
exports.getSetttingsFlagsApiEndpoint =
  "bx_block_groups/community_forums";
exports.getSetttingsFlagsApiMethod = "GET";

//get pending posts and joining requests
exports.getRequestsApiContentType = "application/json";
exports.getPostRequestsApiEndpoint = "bx_block_communityforum/community_forums";
exports.getJoinRequestsApiEndpoint = "bx_block_follow/community_joins";
exports.getRequestsApiMethod = "GET";

//join a community
exports.joinCommunityApiContentType = "application/json";
exports.joinCommunityApiEndpoint = "bx_block_follow/community_joins";
exports.joinCommunityApiMethod = "POST";

//follow a community
exports.followCommunityApiContentType = "application/json";
exports.followCommunityApiEndpoint = "bx_block_follow/community_joins/follow";
exports.followCommunityApiMethod = "PUT";

//get single post data
exports.getPostDataApiContentType = "application/json";
exports.getPostDataApiEndpoint = "bx_block_posts/posts";
exports.getPostDataApiMethod = "GET";

exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";

exports.btnExampleTitle = "CLICK ME";

exports.labelLogOut = "Logout";
exports.labelProfile = "Profile";
exports.labelSetting = "Setting";
exports.labelApplied = "applied";
exports.labelHome = "Home";
exports.labelMyNetwork = "My Network";
exports.labelCommunity = "Community";
exports.labelChat = "Chat";
exports.labelAt = "at";
exports.labelCommunityForum = "Community Forums";
exports.labelWorkAnniversaries = "Upcoming Work Anniversaries";
exports.labelBirthAnniversaries = "Upcoming Birth Anniversaries";
exports.labelKnownPeople = "People you may know";
exports.labelInvitations = "Invitations";
exports.label = "";

exports.placeHolder = "";
exports.placeHolderSearchContact = "Search Contact";
exports.placeHolderWhatOnMind = "What's on your mind?";

exports.btnLearnMore = "Learn More";
exports.btnCreateCommunityPost = "Create Post for Game Development";
exports.btn = "";
//accept/reject communityJoinRequest
exports.acceptRejectJoinRequestApiContentType = "application/json";
exports.acceptRejectJoinRequestApiEndpoint = "bx_block_follow/community_joins";
exports.acceptRejectJoinRequestApiMethod = "PUT";

exports.acceptRejectPostRequestApiContentType = "application/json";
exports.acceptRejectPostRequestApiEndpoint = "bx_block_posts/posts";
exports.acceptRejectPostRequestApiMethod = "POST";

exports.communityForumApi = "bx_block_communityforum/community_forums";

//block a user
exports.blockUserApiContentType = "multipart/form-data";
exports.blockUserApiEndpoint = "bx_block_follow/community_blocks";
exports.blockUserApiMethod = "POST";

// get reviews for me
exports.getMyReviewsApiEndpoint = "bx_block_reviews/reviews";
// get reviews
exports.getReviewsApiEndpoint = "bx_block_reviews/reviews/reviews_sent";
// get ratingreceived
exports.getRatingReceivedApiEndpoint = "account_block/accounts/ratings_received";
// get ratingsend
exports.getRatingSendApiEndpoint = "account_block/accounts/ratings_sent";
// get WorkAnniversaries
exports.getWorkAnniversariesApiEndpoint = "account_block/accounts/anniversaries";
// get My Saved Posts
exports.getMySavedPostsApiEndPoint = "bx_block_posts/posts/saved_posts";
// get My Hide Posts
exports.getMyHidePostsApiEndPoint = "bx_block_posts/posts/hidden_posts";
// get Followers
exports.getConnectionApiCallIdEndPoint = "bx_block_request_management/requests?status=accepted";
exports.getFollowersApiCallIdEndPoint = "account_block/accounts/followers_list";
// get Followings
exports.getFollowingsApiCallIdEndPoint = "account_block/accounts/following_list";

exports.followContentType = "application/json";
exports.followEndPoint = "bx_block_follow/follows";
exports.followMethodType = "POST";

exports.addReviewContentType = "application/json";
exports.addReviewEndPoint = "bx_block_reviews/reviews";
exports.addReviewMethodType = "PUT";

// hide a post
exports.hidePostApiContentType = "application/json";
exports.hidePostApiEndpoint = "bx_block_posts/posts";
exports.hidePostApiMethod = "POST";

exports.reportPostApiContentType = "application/json";
exports.reportPostApiEndpoint = "bx_block_posts/posts";
exports.reportPostApiMethod = "POST";

exports.postBackToCommunityApiContentType = "application/json";
exports.postBackToCommunityApiEndPoint = "bx_block_posts/posts";
exports.postBackToCommunityMethodType = "POST";

//stripe payment
exports.payAndSubscribeEndPoint = "bx_block_payments/payments/pay_and_subscribe";
//newssubscribe
exports.postNewsSubscribe = "bx_block_contact_us/newsletter";
exports.postNewsSubscribeMethodType = "POST";

// newsUnsubscribe
exports.postNewsUnSubscribe = "bx_block_contact_us/news_letter_unsubscribe";
exports.postNewsUnSubscribeMethodType = "GET";

//getpostbyIdForShare
exports.getPostByIdForshare = "bx_block_posts/posts";
exports.getPostByIdForshareMethodType = "GET";

//getpostbyIdForHidePost
exports.getPostByIdForHidePost = "bx_block_posts/posts";
exports.getPostByIdForHidePostMethodType = "GET";

exports.stripePaymentPPIEndPoint = "bx_block_payments/payments";
exports.httpPostType = "POST";

//getallsubscribers
exports.getAllSubscribers = "bx_block_subscriptions/account_subscriptions"
exports.getAllSubscribersMethod = "GET";

//getcustomerinfo
exports.getCustomerINFOEndPoint = "bx_block_payments/payments/customer_info";
exports.getCustomerINFOMethod = "GET";

exports.transactionSucceessEndPoint = "bx_block_subscriptions/account_subscriptions/6/subscribe";
exports.MULTIPARTFormData = "multipart/form-data";

//getNetworkRelation
exports.getNetworkRelation = "bx_block_request_management/requests/relations"

// delete post 
exports.deletePostEndPoint = "bx_block_posts/posts";
//getNetworkRelation
exports.getNetworkRelation = "bx_block_request_management/requests/relations"
// Net work accept connections
exports.getNetConnectionEndPoint = "bx_block_request_management/requests";
//admin invite community
exports.postAdmininviteCommunity = "/bx_block_follow/community_joins/multiple_account_admin_invite";
exports.sendInvitePost = "POST";
// download resume
exports.downloadResumeEndPoint = "bx_block_cvresumecandidatemanagement/resume";

// Customizable Area End
