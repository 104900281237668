import React, { useEffect } from "react";
import QuestionnaireController, { Props } from "./QuestionnaireController.web";
import { Tabs, Alert, Modal } from "antd";
import QuestionComponent from "./QuestionComponent.web";
import { Grid, withStyles, Button, Box, CircularProgress } from "@material-ui/core";
const { TabPane } = Tabs;
import {
    Row,
    Col,
    Typography
} from "antd";
// import CustomHeader from "../CustomHeader.web";
const { Text } = Typography;



class QuestionnaireTabs extends QuestionnaireController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start  
        // Customizable Area End
    }

    render() {
        const { classes } = this.props;
        const { pageLoader, tabData, disabledfield, alertView } = this.state;
        const handleAlert = this.handleAlert;
        const tabChange = (val: any) => {
            // console.log(val);
            this.setState({
                tabValue: val
            })
            if (!val) {
                handleAlert(this.state.tabValue);
            }
        }
        const pageLoading = <Grid container style={{ display: "flex", width: '100%', minHeight: "200px" }}>
            <Box style={{ margin: 'auto' }}>
                <CircularProgress />
            </Box>
        </Grid>;
        return (
            <React.Fragment>
                <div style={{ backgroundColor: "#f6f6f6" }}>
                    {this.redirectUser()}
                    {/* <CustomHeader
                        dotVisible={true}
                        {...this.props}
                    /> */}
                    <Grid container className={classes.fullContainer}>
                        <Row style={{ padding: "10px 20px" }}>
                            <Text
                                className="textlabel_subheadingText"
                            >
                                My Profile /
                            </Text>
                            <Text
                                className="textlabel_subheadingText2"
                            >
                                {" "}
                                Update Questionnaire
                            </Text>
                        </Row>
                        <Grid item xs={12} style={{ padding: "10px 20px" }}>
                            <Text
                                className="textlabel_headingText"
                            >
                                Update Questionnaire
                            </Text>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "10px 20px" }}>
                            <Tabs
                                activeKey={this.state.tabValue}
                                style={{ backgroundColor: "#F1F0F5" }}
                                onChange={(value: any) => {
                                    this.setState({
                                        tabValue: value
                                    })
                                }}
                            >
                                {
                                    pageLoader ? pageLoading : tabData.map((tabItem: any, index1: any) => {
                                        let backButton;
                                        const nextKey = tabData[index1 + 1] && tabData[index1 + 1][0];
                                        if (index1 > 0) {
                                            const backKey = tabData[index1 - 1][0];
                                            backButton = <Button onClick={() => tabChange(backKey)} variant="contained" className={classes.backTab} disabled={this.state.saveAndNextButton}>Back</Button>;
                                        }
                                        let msg;
                                        if (disabledfield !== 0) {
                                            msg = <Alert message="Please Save Edited parameter First" type="error" style={{ margin: 5, borderRadius: 5 }} />
                                        }
                                        console.log(tabItem[1])
                                        // this.setState({ questionName: tabItem[1] });
                                        const tabname = tabItem[0].includes("Customer") ? "Customer" :
                                            tabItem[0].includes("Community") ? "Community" :
                                                tabItem[0].includes("Leader") ? "Leadership" :
                                                    tabItem[0]
                                        return (
                                            <TabPane tab={`${tabname} View`} key={tabItem[0]} style={{ padding: 20, fontSize: '18px' }}>
                                                <Grid container spacing={2}>
                                                    {
                                                        tabItem[1].map((val: any, index: number) =>
                                                            <Grid item xs={12} sm={6} md={4} xl={4} key={`item${tabItem[0]}${index}`} >
                                                                <QuestionComponent
                                                                    classes={classes}
                                                                    questObj={val}
                                                                    index={index}
                                                                    saveChanges={this.saveChangedQuestionnaire}
                                                                    handleSaveNext={this.handleSaveAndNext}
                                                                />
                                                            </Grid>)

                                                    }
                                                    <Grid item xs={12} className={classes.nextTabContainer}>
                                                        {/* {msg} */}
                                                        {backButton}
                                                        <Button
                                                            onClick={() => tabChange(nextKey)}
                                                            variant="contained"
                                                            className={classes.saveNextTab}
                                                            disabled={this.state.saveAndNextButton}
                                                        >
                                                            {tabData.length == index1 + 1 ? "Save" : "Save & Next"}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </TabPane>
                                        )

                                    })
                                }

                                {/* <TabPane tab="Team View" key="team_view" style={{ marginLeft: 10 }}>
                                    {pageLoader ? pageLoading : <Grid container spacing={2}>
                                        {
                                            tabData.team.map((val: any, index: number) => <Grid item xs={4} key={index + "1"}>
                                                <QuestionComponent classes={classes} questObj={val} />
                                            </Grid>)
                                        }
                                        <Grid item xs={12} className={classes.nextTabContainer}>
                                            <Button onClick={() => tabChange('Leadership_view')} variant="contained" className={classes.backTab}>Back</Button>
                                            <Button onClick={() => tabChange('customer_view')} variant="contained" className={classes.saveNextTab}>Save & Next</Button>
                                        </Grid>
                                    </Grid>}

                                </TabPane>

                                <TabPane tab="Customer View" key="customer_view" style={{ marginLeft: 10 }}>
                                    {pageLoader ? pageLoading : <Grid container spacing={2}>
                                        {
                                            tabData.customer.map((val: any, index: number) => <Grid item xs={4} key={index + "1"}>
                                                <QuestionComponent classes={classes} questObj={val} />
                                            </Grid>)
                                        }

                                        <Grid item xs={12} className={classes.nextTabContainer}>
                                            <Button onClick={() => tabChange('team_view')} variant="contained" className={classes.backTab}>Back</Button>
                                            <Button onClick={() => tabChange('community_view')} variant="contained" className={classes.saveNextTab}>Save & Next</Button>
                                        </Grid>
                                    </Grid>}

                                </TabPane>
                                <TabPane tab="Community View" key="community_view" style={{ marginLeft: 10 }}>
                                    {pageLoader ? pageLoading : <Grid container>
                                        {
                                            tabData.community.map((val: any, index: number) => <Grid item xs={4} key={index + "1"}>
                                                <QuestionComponent classes={classes} questObj={val} />
                                            </Grid>)
                                        }

                                        <Grid item xs={12} className={classes.nextTabContainer}>
                                            <Button onClick={() => tabChange('customer_view')} variant="contained" className={classes.saveNextTab}>Back</Button>
                                            <Button variant="contained" className={classes.saveNextTab}>Save</Button>
                                        </Grid>
                                    </Grid>}

                                </TabPane> */}
                            </Tabs>
                            <Modal
                                title="Update Questionnaire"
                                visible={alertView}
                                onOk={() => {
                                    handleAlert(this.state.tabValue)
                                    this.handleSaveAndNext(true);
                                }}
                                onCancel={() => {
                                    handleAlert(this.state.tabValue)
                                    this.handleSaveAndNext(true);
                                }}
                                footer={
                                    <Button
                                        key="submit"
                                        onClick={() => {
                                            handleAlert(this.state.tabValue);
                                            this.handleSaveAndNext(true);
                                        }}
                                    >
                                        Ok
                                    </Button>}
                            >
                                <Text>Questionnaire Updated Successfully</Text>
                            </Modal>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment >
        )
    }
}


const styles = (theme: any) => ({
    fullContainer: {
        padding: '20px 10px'
    },
    formController: {
        padding: "5px 10px",
        marginBottom: '20px',
        [theme.breakpoints.down('281')]: {
            padding: 0,
        },
    },
    labelProps: {
        fontSize: "16px",
        fontWeight: 400,
        color: '#c3c3c3!important',
    },
    textFieldRoot: {
        // backgroundColor: "#00000000",
        padding: "5px",
    },
    cssOutlinedInput1: {
        // backgroundColor: "#e6e6e6",
        borderRadius: 27,
        marginTop: "25px!important",
        padding: "4px 20px",
        border: '1px solid #b4c2ce'
        ,
        [theme.breakpoints.up('xs')]: {
            marginTop: "33px!important",
        },
    },
    cssOutlinedInput2: {
        // backgroundColor: "#e6e6e6",
        borderRadius: 6,
        marginTop: "25px!important",
        padding: "7px 20px",
        border: '1px solid #b4c2ce'
        ,
        [theme.breakpoints.up('xs')]: {
            marginTop: "33px!important",
        },
    },
    editButton: {
        backgroundImage: "linear-gradient(#00536c, #007491)!important",
        borderColor: '#007491',
        borderRadius: 20,
        color: "white",
        padding: "7px 47px",
        marginLeft: 'auto',
        marginRight: '10px',
        fontSize: 14,
        fontWeight: 600
    },
    saveNextTab: {
        // backgroundColor: "#045878!important",
        backgroundImage: "linear-gradient(#00536c, #007491)!important",
        borderColor: '#007491',
        borderRadius: 20,
        color: "white",
        padding: "7px 53px",
        marginRight: '10px',
        fontSize: 14,
        fontWeight: 500,
        [theme.breakpoints.up('xs')]: {
            padding: "7px 25px",
        },
    },
    backTab: {
        border: "1px solid #045878!important",
        borderRadius: 20,
        color: "#045878",
        padding: "7px 65px",
        marginRight: '10px',
        [theme.breakpoints.up('xs')]: {
            padding: "7px 26px",
        },
    },
    nextTabContainer: {
        margin: '25px 0px',
        display: 'flex',
        justifyContent: 'end',
        [theme.breakpoints.down('281')]: {
            flexDirection: "row",
            flexWrap: "wrap-reverse",
            gap: "10px"
        },
    }
});

export default withStyles(styles)(QuestionnaireTabs);
